export const mantrasEnglish = [
    'Today I deserve to be calm and feel good about myself. ♥',
    'I deserve all the beautiful things in the world.',
    'Soon I will smile again and say: Thank you, life, this is more than I asked for!',
    'May all the beauty surprise me, may my steps move in the right direction, and may all the doors leading to happiness open for me.',
    'Today, I allow myself to be happy.',
    'I am going to achieve great things.',
    'I vibrate high and attract all the beauty the universe has for me.',
    'Everything I dream, everything I long for, will come into my life.',
    'Here we go, slowly but with the certainty that one day I will fulfill all my dreams.',
    'I am strong, magical, and deserving of all the good that happens to me.',
    'I am blessed by the universe; if I want something, I get it.',
    'Today, I am open to receiving more than I desire.',
    'Today, I declare that I am about to experience a very successful stage of my life.',
    'Everything has its perfect timing; today, I let go and trust the process.',
    'My potential is infinite, my energy is inexhaustible, my strength is immense.',
    'I am beautiful; I attract positive energy, opportunities, and abundance.',
    'I let go of my fears and limitations and allow myself to BELIEVE in myself.',
    'The best time to start over is NOW.',
    'I embrace all that I am while I seek to become what I want to be.',
    'I am capable of facing any challenge that comes my way.',
    'Peace and serenity are within me. I find calm in the midst of chaos.',
    'Every day is an opportunity to grow and learn. I make the most of each moment.',
    'My mind is clear, and my heart is at peace. I am in charge of my own well-being.',
    'I attract positivity and abundance into my life with every thought and action.',
    'I am love, I am light, I am gratitude. I live in harmony with the universe.',
    'Happiness is a choice, and I choose to be happy in this moment.',
    'My body is my temple. I take care of it, respect it, and love it deeply.',
    'I trust the process of life, and I know that everything happens at the right time.',
    'I am at peace with my past, live in the present, and trust in my future.',
];
