import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from '../../i18n';

const SEO = () => {
    const { t } = useTranslation();
    return (
        <Helmet
            title={t('metatitle')}
            htmlAttributes={{
                lang: i18n.language,
            }}
        >
            <meta name="description" content={t('metadescription')} />
            <meta name="og:url" content="http://affirmationdailycard.com/" />
            <meta property="og:title" content={t('metatitle')} />
            <meta property="og:description" content={t('metadescription')} />
        </Helmet>
    );
};

export default SEO;
