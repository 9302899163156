import React from 'react';
import { useTranslation } from 'react-i18next';
import './About.scss';

const About = () => {
    const { t } = useTranslation();
    return (
        <div className={`section-message about`}>
            <div className="content" dangerouslySetInnerHTML={{ __html: t('AboutContent') }} />
        </div>
    );
};

export default About;
