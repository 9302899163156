export const mantras = [
  "Hoy merezco estar tranquila y sentirme bien conmigo misma. ♥",
  "Me merezco todas las cosas bonitas del mundo.",
  "Muy pronto voy a sonreír de nuevo y diré: ¡Gracias vida, estoy es más de lo que pedí!",
  "Que todo lo bonito me sorprenda, que mis pasos avancen en el camino correcto y que se abran todas las puertas que me lleven a la felicidad.",
  "Hoy me permito ser feliz.",
  "Voy a lograr grandes cosas.",
  "Vibro alto y atraigo todo lo bonito que el universo tiene para mí.",
  "Todo eso que sueño, todo eso que anhelo, sí llegará a mi vida.",
  "Aquí vamos, lentico, pero con la certeza de que un día voy a cumplir con todos mis sueños.",
  "Soy fuerte, mágica, y merezco todo lo bueno que me pase.",
  "Estoy bendecida por el universo, si quiero algo, lo consigo.",
  "Hoy me abro a recibir más de lo que deseo.",
  "Hoy declaro que estoy por vivir una etapa muy exitosa de mi vida.",
  "Todo tiene su tiempo perfecto, hoy suelto y confío en el proceso.",
  "Mi potencial es infinito, mi energía es inagotable, mi fuerza es inmensa.",
  "Soy hermosa, atraigo energía positiva, atraigo oportunidades, atraigo abundancia.",
  "Me deshago de mis miedos y limitaciones y me permito CREER en mi misma.",
  "El mejor momento para comenzar de nuevo es AHORA.",
  "Abrazo todo lo que soy mientras voy en busca de lo que quiero ser.",
  "Soy capaz de enfrentar cualquier desafío que se presente en mi camino.",
  "La paz y la serenidad están dentro de mí. Encuentro calma en medio del caos.",
  "Cada día es una oportunidad para crecer y aprender. Aprovecho al máximo cada momento.",
  "Mi mente está clara y mi corazón está en paz. Soy dueño de mi propio bienestar.",
  "Atraigo la positividad y la abundancia hacia mi vida con cada pensamiento y acción.",
  "Soy amor, soy luz, soy gratitud. Vivo en armonía con el universo.",
  "La felicidad es una elección y yo elijo ser feliz en este momento.",
  "Mi cuerpo es mi templo. Lo cuido, lo respeto y lo amo profundamente.",
  "Confío en el proceso de la vida y sé que todo sucede en el momento adecuado.",
  "Estoy en paz con mi pasado, vivo en el presente y confío en mi futuro.",
];
