import React from 'react';
import { Animated } from 'react-animated-css';
import './Quote.scss';

function Quote({ quote }) {
    return (
        <Animated animationIn="fadeIn" isVisible={true} key={quote}>
            <div className="quote">
                <h1>{quote}</h1>
            </div>
        </Animated>
    );
}

export default Quote;
