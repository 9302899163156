import React from 'react';
import i18n from '../../i18n';
import { mantras } from '../../data/mantras.js';
import { mantrasEnglish } from '../../data/mantras-en.js';
import Quote from '../../components/Quote/Quote.js';
import NextButton from '../../components/NextButton/NextButton';

const colorTotal = 15;
const QuoteOfTheDay = () => {
    const quotes = i18n.language === 'en' ? mantrasEnglish : mantras;
    const [currentColorId, setCurrentColorId] = React.useState(() => Math.floor(Math.random() * colorTotal + 1));

    //Listing to tab space bar event get a new random quote from the array and return that quote to the user.
    const [quote, setQuote] = React.useState(quotes[Math.floor(Math.random() * quotes.length)]);

    const generateQuote = React.useCallback(() => {
        setCurrentColorId((prev) => (prev === colorTotal ? 1 : prev + 1));
        return setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
    }, [quotes]);

    //Add an event listener to the document that listens for the space bar keydown event.
    React.useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode === 32 || e.keyCode === 13) {
                e.preventDefault();
                generateQuote();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [generateQuote]);

    return (
        <div className={`section-message bg-${currentColorId}`}>
            <Quote quote={quote} />
            <NextButton onClick={() => generateQuote()} />
        </div>
    );
};

export default QuoteOfTheDay;
