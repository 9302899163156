import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from '../../i18n';
import './LanguageSelector.scss';

const LanguageSelector = () => {
    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const changeLanguage = (nextLanguage) => {
        if (selectedLanguage === nextLanguage) return;
        i18n.changeLanguage(nextLanguage); // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(nextLanguage);
        localStorage.setItem('lang', nextLanguage);
        //change url of the page
        navigate({
            pathname: `/`,
            search: `?lang=${nextLanguage}`,
            replace: true,
        });
    };

    return (
        <div className="language">
            <div
                className={`${selectedLanguage === 'en' ? 'current-language' : ''}`}
                onClick={() => changeLanguage('en')}
            >
                EN
            </div>
            <div
                className={`${selectedLanguage === 'es' ? 'current-language' : ''}`}
                onClick={() => changeLanguage('es')}
            >
                ES
            </div>
        </div>
    );
};

export default LanguageSelector;
