import * as React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import About from '../About/About';
import QuoteOfTheDay from '../../containers/QuoteOfTheDay/QuoteOfTheDay';

export default function Router() {
    /* getting the current lang */
    const lang = localStorage.getItem('lang') || 'en';
    const location = useLocation();
    const search = location.search;
    return (
        <Routes>
            <Route path={`/quote-generator`} element={<QuoteOfTheDay />} key={lang} />
            <Route path={`/about-us`} element={<About />} key={lang} />
            <Route
                exact
                path="/"
                element={
                    search !== '' ? <QuoteOfTheDay key={lang} /> : <Navigate to={`/quote-generator?lang=${lang}`} />
                }
            />
        </Routes>
    );
}
