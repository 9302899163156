import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MenuContainer } from '../../components/Menu/Menu.js';
import Router from '../../components/Router/Router';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import WelcomeMessage from '../../components/WelcomeMessage/WelcomeMessage';
import SEO from '../../components/SEO/SEO.js';
import './App.scss';

function App() {
    const { t } = useTranslation();
    const location = useLocation();
    const { pathname } = location;
    const message = pathname !== '/about-us' ? t('QuoteOfDay') : t('About');
    return (
        <div className={`App`}>
            <SEO />
            <MenuContainer />
            <WelcomeMessage welcomeMessage={message} />
            <LanguageSelector />
            <Router />
        </div>
    );
}

export default App;
