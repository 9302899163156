import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import './Menu.scss';

export const MenuContainer = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    
    const lang = searchParams.get('lang');
    const [isOpen, setOpen] = React.useState(false);

    const handleIsOpen = () => {
        setOpen(!isOpen);
    };

    const closeSideBar = () => {
        setOpen(false);
    };
    return (
        <div className="menu">
            <Menu isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen}>
                <Link to={`/quote-generator?lang=${lang}`} className="menu-item" onClick={closeSideBar}>
                    {t('QuoteOfDay')}
                </Link>
                <Link to={`/about-us?lang=${lang}`} className="menu-item" onClick={closeSideBar}>
                    {t('About')}
                </Link>
            </Menu>
        </div>
    );
};
